import './i18n';
import '../components/layout.css';

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Script } from "gatsby";

import {
  HeroBannerSection,
  PropertiesSection,
  LoremSection,
  FractionalSection,
  BenefitsSection,
  HowSection,
  WhyAncanaSection
} from "../components/sectionsV3";
import ContactSection from "../components/sectionsV3/ContactSection";
import FaqSection from "../components/sectionsV3/FaqSection";
import Footer from "../components/sectionsV3/Footer";
import GallerySection from "../components/sectionsV3/GallerySection";
import MentionsSection from "../components/sectionsV3/MentionsSection";
import NavBar from "../components/sectionsV3/NavBar";
import { scrollToSection } from "../misc/utils";
import { Helmet } from "react-helmet";
import { adminApi } from "../api/ancanaAPI";
import ContactModal from "../components/ContactModal";
import useObserveRef from "../hooks/useObserveRef";


const Container = styled.div`
  overflow-x: hidden;
  // background-color: #FDFBF7;

  .ancana-logo {
    width: 138px;
  }
`;

const mockedListings = [
  {
    "id": 6,
    "name": "UAVI",
    "slug": "uavi",
    "amenities": [
      35,
      37,
      9,
      42,
      10,
      44,
      13,
      14,
      48,
      17,
      49,
      23,
      27,
      29
    ],
    "images": [
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/bcec9b50-50ab-421c-8205-bbb2ea7ba5e4/c4e4df93f8d63dcf8e26204972854de5.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/cc817f75-3d67-471d-9eac-2ce232951ae6/f2dd66663cfbfca387553d420af0b9df.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/791fbb9e-4f64-4d51-aa05-695bbebbdbab/2952da6a783943a18024a605a652e815.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/1ad2ce22-abfa-4441-9216-8f5d3704ebd2/9f39ab2e9da0c2aa7337023cc6b53a1e.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/e2443e83-dc09-4b73-aa0b-1de18b99f159/865d9a07edd860b4da6fe94d8408155b.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/cf6bfa95-9d1f-4291-af43-517fd8f2b507/e51dfc971db314da2ef922fa6504e0b1.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/0397542d-bfe6-4f73-b2f3-0b892277feb3_1/a16571edc5e1849e7b41ba0c125a967e.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/5357d331-0020-4319-864d-7711c43c982a/6dc8a73b0678efb6bfa935d79cdc2a05.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/74c9a3d7-bf37-420c-8064-54bfc87ca535/b3cc8962205ddd1c767edb7e7dcf5805.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/92fcda7b-152d-46b6-9c72-33f205eb1585_2/d225cdf36447bfe4d188eb00d3e91950.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/109e1207-f439-4d29-a3b7-3fa2915bd56a/79472476c8e1e8455e0e45d9f9341a5a.webp"
    ],
    "files": [
      {
        "id": 4,
        "file": "https://api-v1.s3.amazonaws.com/files/UAVI_Ancana_Brochure_Eng.pdf",
        "name": "Brochure en inglés"
      },
      {
        "id": 10,
        "file": "https://api-v1.s3.amazonaws.com/files/UAVI_Ancana_Brochure-1.pdf",
        "name": "Brochure"
      }
    ],
    "lat": "20.792043",
    "lng": "-105.489904",
    "address": "Lote 13, CIP, Litibú, Higuera Blanca, Punta de Mita, Nayarit, Mexico",
    "type": "unit",
    "published": true,
    "status": "new_arrival",
    "location": 1,
    "price": {
      "amount": 427500.0,
      "currency": "USD"
    },
    "characteristics": {
      "square_meters": 405.0,
      "bedrooms": 4.0,
      "bathrooms": 4.5,
      "fractions": 8,
      "weeks_available": 6
    },
    "description_en": "Immerse yourself in the natural beauty and exclusive lifestyle of Punta Mita with UAVI. This extraordinary residential project offers an incomparable home that merges the natural beauty of the mountains with stunning ocean views and direct access to unique services and experiences.\nThe 5 independent towers comprise 44 apartments with 3, 4, and 5 bedrooms and penthouses with terraces and pools, surrounded by over 215,000 square feet of beachfront.\nAmenities include a pool facing the sea, infinity pool and snack bar, beach club, sports courts, gym, spa and wellness center, children's club, resident lounge, creative hub, water sports hut, and concierge. Additionally, UAVI prioritizes the privacy and security of its residents with 131 covered parking spaces, two controlled accesses, and a security system.\nLive in the private paradise of Litibú and expand your horizons at UAVI.",
    "description_es": "Sumérgete en la belleza natural y el estilo de vida exclusivo de PuntaMita con UAVI. Este proyecto residencial extraordinario ofrece un hogar incomparable que fusiona la belleza natural de las montañas con vistas impresionantes del mar y acceso directo a servicios y experiencias únicas. \nLas 5 torres independientes comprenden 44 apartamentos con 3, 4 y 5 habitaciones y penthouses con terrazas y piscinas, rodeados de más de 215,000 pies cuadrados de frente de playa. \nLas amenidades incluyen una piscina frente al mar, infinity pool y snack bar, beach club, canchas deportivas, gimnasio, spa y centro de bienestar, club de niños, salón para residentes, centro creativo, cabaña de deportes acuáticos y conserjería. Además, UAVI prioriza la privacidad y seguridad de sus residentes con 131 estacionamientos techados, dos accesos controlados y sistema de seguridad. \nVive en el paraíso privado de Litibú y amplía tus horizontes en UAVI.",
    "unit": 5,
    "design": null,
    "development": null,
    "cover_image": "https://api-v1.s3.amazonaws.com/CACHE/images/images/bcec9b50-50ab-421c-8205-bbb2ea7ba5e4/dbb900871629ef8c4afd6ac874e09bcf.webp",
    "sort_index": 3
  },
  {
    "id": 3,
    "name": "Bolongo 215",
    "slug": "bolongo-215",
    "amenities": [
      36,
      37,
      40,
      8,
      42,
      11,
      48,
      18,
      51,
      19,
      20,
      52,
      53,
      23
    ],
    "images": [
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/e111e262-894c-4e92-b3ab-6d5e89322e16_1/72921bf17254d8ecab2ffcb833e2a53e.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/b5d2d83c-3749-4888-891b-49a503a28870_1/4ea93fea6755070912155d29b2db7f49.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/346c7ba9-6a85-4088-a9db-488fd3ac1f25_1/259af523e602c5e1a8870cb87f0ea3c5.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/756c6e11-1e63-463b-b7f0-7289fd3541c7_1/e6d007594f17ee5fa549716f8760439a.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/1f507c62-4970-480f-b28a-469507d3b32d_1/1610de1784907bcfa9e607d071bfad0a.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/0698fa34-3eb6-4041-9423-ebd1731ab7be_1/0bc8a8d70e9c538fec5c8625d345f10e.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/88951ff2-3c9e-46ec-8803-4207c56a4270_1/f9a6e0b6fbe1bc33bf8b0cd2f517450b.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/ba3a1d37-4a7f-4d6a-bf4b-0cbe5ae3ddb1_1/820a97adcece6cf6f4ef42ce0a5bdc7d.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/08877f06-a46c-40db-8190-32850818dad8_1/49f7317aca4dba602f8d9641036d1d16.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/379fb157-e5ae-4903-91b9-374a98918997_1/b7eeb06fc67be3b15e9b859a79c82afb.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/c5744b75-c607-470a-9278-b49ed178a716_1/706d015f49e4bb7678e1e3bfd9d657bc.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/7fd08d80-f50a-4a17-bd2f-e6cb912dce71_1/bb57e7f83159ad439a638478adc1d595.webp"
    ],
    "files": [
      {
        "id": 11,
        "file": "https://api-v1.s3.amazonaws.com/files/Bolongo_215_Ancana_Brochure.pdf",
        "name": "Brochure"
      }
    ],
    "lat": "20.754645",
    "lng": "-105.461564",
    "address": "Bahia de Banderas, Nayarit, México",
    "type": "unit",
    "published": true,
    "status": "available_now",
    "location": 1,
    "price": {
      "amount": 1798000.0,
      "currency": "MXN"
    },
    "characteristics": {
      "square_meters": 132.0,
      "bedrooms": 2.0,
      "bathrooms": 2.0,
      "fractions": 8,
      "weeks_available": 6
    },
    "description_en": "On the shores of the Pacific, along mountainous coastal terrain, is the exclusive and premium beach property of Bolongo. Winding pathways, rich flora and fauna, and spectacular architecture spread across 54 acres of pristine coastline blend in perfect harmony. The white-themed minimalistic design showcases Bolongo as a glistening curvy structure facing the sea. We have 2 and 3 bedroom flats with spacious living areas and magnificent interiors, integrating the best and latest facilities of luxury and comfort.\n\nExplore the beach club that opens to sandy shores and immerse yourself in endless fun-filled activities! Experience comfortable temperatures throughout the year, relish breath-taking scenic landscapes and blue ocean views. Step out to double terraces from your primary bedroom or living area. Plunge into the infinity pool, relax and rejuvenate in the spa, hit the gym, and savor world-class delicacies in the acclaimed restaurant.",
    "description_es": "A orillas del Pacífico, a lo largo de un terreno costero montañoso, se encuentra la exclusiva propiedad de playa Bolongo. Los caminos sinuosos, la rica flora y fauna y la espectacular arquitectura se extienden a lo largo de 54 acres de costa virgen, que se mezclan en perfecta armonía. El diseño minimalista de temática blanca muestra a Bolongo como una reluciente estructura curva frente al mar. Disponemos de viviendas de 2 y 3 dormitorios con amplios salones y magníficos interiores, integrando las mejores y más modernas instalaciones del lujo y confort.\n\n¡Explore el club de playa que se abre a las costas arenosas y sumérjase en un sinfín de actividades llenas de diversión! Experimente temperaturas agradables a lo largo del año, disfrute de impresionantes paisajes escénicos y vistas al azul océano. Salga a las terrazas dobles desde su sala de estar o recámara y sumérjase en la piscina infinita. Relájate y rejuvenece en el Spa, ejercita en el gimnasio y saborea el aclamado menú del exquisito restaurante.",
    "unit": 1,
    "design": null,
    "development": null,
    "cover_image": "https://api-v1.s3.amazonaws.com/CACHE/images/images/e111e262-894c-4e92-b3ab-6d5e89322e16_1/a161c61ee877c19d6f851122f5e8a410.webp",
    "sort_index": 7
  },
  {
    "id": 11,
    "name": "Narval 606",
    "slug": "narval-606",
    "amenities": [
      36,
      37,
      6,
      8,
      9,
      42,
      10,
      44,
      48,
      49,
      53,
      29,
      51,
      20,
      23,
      28,
      30,
      31
    ],
    "images": [
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/3e4f11b3-8f91-4742-972a-af2815059666/0942035bd19fb734d395f594e2d31599.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/ecf3b585-ab78-486c-ada0-2196bc456a53/8038d4accbe36507738262b25f00fb6f.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/25c91145-695c-41f1-b5b7-a1878e7d83ca/ba210fa2d42a6dc8be1642702b12d227.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/4f8509df-bd97-4c6b-8bd8-bc2eb5c66be8/d373e9655a4958f7675b2055d615ed6c.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/154b7090-04ad-40c5-9855-b195a7497128/24be32d658b91b8ef93b2b29e5d896a5.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/96635232-5b2f-4a42-9a47-5e22861a0bc5/5f4729a325eec4a673d3c7de979585ce.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/82833cf9-372f-4233-a29c-22ed21310aa3/7677eb956a157e4f7784e9d8effd0ee5.webp"
    ],
    "files": [
      {
        "id": 13,
        "file": "https://api-v1.s3.amazonaws.com/files/Narval_Ancana_Brochure.pdf",
        "name": "Brochure"
      }
    ],
    "lat": "20.763695",
    "lng": "-105.363133",
    "address": "Av. Playa los Picos.961, Punta de Mita, Nayarit, Mexico",
    "type": "unit",
    "published": true,
    "status": "pre_sale",
    "location": 1,
    "price": {
      "amount": 1890000.0,
      "currency": "MXN"
    },
    "characteristics": {
      "square_meters": 164.0,
      "bedrooms": 2.0,
      "bathrooms": 2.0,
      "fractions": 8,
      "weeks_available": 6
    },
    "description_en": "Welcome to Narval, the perfect place to experience luxury and tranquility. The exquisitely designed residences offer unparalleled indoor and outdoor amenities. Located between Punta Mita, Bucerías, and La Cruz de Huanacaxtle, Narval invites you to enjoy nature and the most exclusive developments on the Pacific coast.\nHere, you'll find exclusive amenities, such as UMI - Restaurant, where you can indulge in delicious fusion cuisine and cocktails in a maritime setting. Relax and have unforgettable moments by the Infinity Pool, with breathtaking ocean views. Discover physical and mental balance at the Spa, and stay fit in the world-class gym.\nAt Narval, architectural design blends with luxury and comfort, complemented by the charm of La Cruz de Huanacaxtle, a picturesque fishing village with a modern marina and excellent dining options.\nDeveloped by Península Residences, Narval provides an exclusive lifestyle in incredible surroundings. The luxury units are complemented by amenities that exceed your expectations.\n\nDiscover luxury and elegance at Narval. Come and live in paradise!",
    "description_es": "Bienvenido a Narval, el lugar perfecto para vivir el lujo y la tranquilidad. Las residencias exquisitamente diseñadas te ofrecen comodidades interiores y exteriores incomparables. Ubicado entre Punta Mita, Bucerías y la Cruz de Huanacaxtle, Narval te invita a disfrutar de la naturaleza y de los desarrollos más exclusivos del Pacífico.\nAquí encontrarás amenidades exclusivas, como el UMI - Restaurante, donde podrás deleitarte con deliciosa gastronomía y cocteles fusionados en un ambiente marítimo. Disfruta de la relajante Infinity Pool, con vistas al mar, y crea recuerdos inolvidables. Descubre el equilibrio físico y mental en el Spa, y mantente en forma en el gimnasio de primer nivel.\nEn Narval, el diseño arquitectónico se fusiona con el lujo y el confort, complementado por el encanto de La Cruz de Huanacaxtle, un pintoresco pueblo de pescadores con una moderna marina y excelentes opciones gastronómicas.\nDesarrollado por Península Residences, Narval te brinda un estilo de vida exclusivo en entornos increíbles. Las unidades de lujo se complementan con amenidades que superan tus expectativas.\n\nDescubre el lujo y la elegancia en Narval. ¡Ven y vive en el paraíso!",
    "unit": 10,
    "design": null,
    "development": null,
    "cover_image": "https://api-v1.s3.amazonaws.com/CACHE/images/images/3e4f11b3-8f91-4742-972a-af2815059666/5d8e15af51f808e825f502ecaceb24c5.webp",
    "sort_index": 9
  }
];

const V2Page = ({ pageContext }) => {
  const [listings, setListings] = useState(mockedListings);
  const containerRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [alreadyPopedup, setAlreadyPopedup] = useState(false);
  const alreadyPopedupRef = useRef(alreadyPopedup);

  const openPopup = () => {
    if(!alreadyPopedupRef.current) {
      setPopupVisible(true);
      // alreadyPopedupRef.current = true;
      setAlreadyPopedup(true);
    }
  }

  const { observedRef } = useObserveRef(alreadyPopedup, openPopup);

  const scrollTo = (name, offset = 0, cancelPopup = true) => {
    if (cancelPopup) setAlreadyPopedup(true);
    scrollToSection(name, containerRef, offset);
  }

  useEffect(() => {
    adminApi.get('listings/?page_size=8&published=true&location_slug=puerto-vallarta').then(res => {
      setListings(res.data.results)
    });
    const timeout = setTimeout(() => openPopup(), 15000);
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    alreadyPopedupRef.current = alreadyPopedup;
  }, [alreadyPopedup]);

  return (
    <ParallaxProvider>
      <Container ref={containerRef}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ancana - Puerto Vallarta</title>
          <meta name={`robots`} content={`noindex, nofollow`} />
        </Helmet>
        <NavBar scrollTo={scrollTo} />

        <div id="hero">
          <HeroBannerSection scrollTo={scrollTo} />
        </div>
        <div id="property">
          <PropertiesSection listings={listings} scrollTo={scrollTo} />
        </div>
        <LoremSection />
        <div id="fractional">
          <FractionalSection />
        </div>
          <BenefitsSection />
        <div ref={observedRef}>
         <HowSection />
        </div>
        <WhyAncanaSection />
        <div id="contact">
          <ContactSection />
        </div>

        <GallerySection houses={listings} />
        <MentionsSection />

        <FaqSection />

        <Footer scrollTo={(name) => scrollToSection(name, containerRef)} />
      </Container>

      <ContactModal
        visible={popupVisible}
        handleClose={() => setPopupVisible(false)}
      />
    </ParallaxProvider>
  );
}

export default V2Page;
