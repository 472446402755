import React from "react";
import styled from "styled-components";
import H from "../text/H";
import P from "../text/P";
import Ancana from '../../images/icons/ancana-whitish.inline.svg';
import Check from '../../images/icons/ccheck.inline.svg';
import Cross from '../../images/icons/ccross.inline.svg';
import { bravoPalette, breakPoints, corporatePalette } from "../../misc/consts";
import B from "../text/B";

const Container = styled.div`
  .tables {
    flex-direction: column;
    ${breakPoints.desktop} {
      flex-direction: row;
    }
  }
`;

function WhyAncanaSection() {
  return (
    <Container className="white-bg">
      <H className="fs-5 center">¿Por qué comprar con Ancana?</H>
      <P className="fs-3 center-self">Ancana, mucho más que solo ser dueño</P>

      <div className="marginal mt-lg white-bg" style={{ overflow: 'visible', zIndex: 3, maxWidth: 900 }}>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.blue }}>
          <div className="flx1"></div>
          <div className="flx1 fw-400 center-text" style={{ color: bravoPalette.almostWhite }}>ANCANA</div>
          <div className="flx1 fw-400 center-text" style={{ color: bravoPalette.almostWhite }}>Casa completa</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Precio</div>
          <div className="flx1 fw-600 center" style={{ color: bravoPalette.dryLeaf }}>$1,798,000 MXN <small>por ⅛ de fracción</small></div>
          <div className="flx1 fw-600 center" style={{ color: bravoPalette.dryLeaf }}>$14,384,000 MXN</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1 fw-600">Mantinimiento</div>
          <div className="flx1 fw-600 center" style={{ color: bravoPalette.dryLeaf }}>$6,300 MXN</div>
          <div className="flx1 fw-600 center" style={{ color: bravoPalette.dryLeaf }}>$50,400 MXN</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Membresía de Thirdhome para intercambio de propiedad a precio especial</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Check /> Incluído</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Cross />No incluído</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1 fw-600">Amueblado, decorado y equipamiento de lujo</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Check /> Incluído</div>
          <div className="flx1 center">$50,000 USD apróx.</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Administración hotelera</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Check /> Incluído</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Cross />No incluído</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1 fw-600">Servicio de limpieza</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Check /> Incluído</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Cross />No incluído</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Luz, agua, gas y wifi</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Check /> Incluído</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Cross />No incluído</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1 fw-600">Concierge</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Check /> Incluído</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Cross />No incluído</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Periodo para ocuparse y disfrutarse</div>
          <div className="flx1 center">Inmediatamente</div>
          <div className="flx1 center center-text">Después de amueblarlo y equiparlo</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1 fw-600">Responsabilidad sobre el inmueble</div>
          <div className="flx1 center">Compartida y mínima</div>
          <div className="flx1 center">Total y constante</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Costos legales de escrituración  y notariales</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Check /> Incluído</div>
          <div className="flx1 h-center center h-stack-strict gap-xs"><Cross />No incluído</div>
        </div>
        <div className="full-width mb-xl" />
      </div>

      <br/><br/><br/>
    </Container>
  )
}

export default WhyAncanaSection;
