import React from "react";
import styled from "styled-components";
import H from "../text/H";
import P from "../text/P";
import B from "../text/B";
import HouseMaintenance from '../../images/icons/house-maintenance.inline.svg';
import HouseAdmin from '../../images/icons/house-admin.inline.svg';
import { bravoPalette, breakPoints } from "../../misc/consts";

const Container = styled.div`
  background-color: #FAFAFA;

  .content {
    flex-direction: column;
    ${breakPoints.tablet} {
      flex-direction: column-reverse;
    }
    ${breakPoints.largeDesktop} {
      flex-direction: row;
    }
  }

  .special-text {
    text-align: center;

    ${breakPoints.largeDesktop} {
      text-align: left;
    }
  }

  .cards {
    gap: 20px;
    ${breakPoints.tablet} {
      flex-direction: column;
    }
    ${breakPoints.desktop} {
      flex-direction: row;
      gap: 80px;
    }
    ${breakPoints.largeDesktop} {
      gap: 20px;
    }
  }
`;

const Card = styled.div`
  background-color: white;
  padding: 10px;
  max-width: 280px;
  align-self: center;
  height: 100%;

  ${breakPoints.tablet} {
    max-width: 1000px;
  }
`;

function FractionalSection() {
  return (
    <Container>
      <div className="marginal">
        <div className="center mb-lg">
          <H className="fs-5">¿Qué es fractional?</H>
          <P className="fs-3">Ancana: más allá de ser propietario</P>
        </div>
        <div className="h-stack content">
          <div style={{ flex: 1 }} className="h-center">
            <P className="fs-4 fw-800 mb-sm mobile-only center-text center-self" color={bravoPalette.dryLeaf}>
              LA MEJOR MANERA DE <br/> COMPRAR CASAS EN PUERTO VALLARTA
            </P>
            <H className="fs-4 mb-sm center-text tablet-and-desktop">La mejor manera de comprar casa en Puerto Vallarta</H>
            <H className="fs-4 mb-sm large-desktop-only">La mejor manera de comprar casa en Puerto Vallarta</H>
            <P className="fs-2-33 mb-lg special-text">
              La compra fraccional te ofrece todos los beneficios de ser co-dueño sin los inconvenientes, y todo por una fracción del costo.
            </P>
            <div className="cards h-stack shadow-partner">
              <Card className="shadow-1 corp-shadow-sm-hov">
                <HouseMaintenance className="mb-sm" />
                <B className="fs-2-66 fw-underline mb-sm">Gastos de mantenimiento</B>
                <P className="fs-2-33">
                  Cuando adquieres una fracción con <b>ANCANA</b>, tu y los otros  co-dueños adquieren la propiedad y <b>dividen equitativamente todos los gastos</b>.
                </P>
              </Card>
              <Card className="shadow-1 corp-shadow-sm-hov">
                <HouseAdmin className="mb-sm" />
                <B className="fs-2-66 fw-underline mb-sm">Administración de la propiedad</B>
                <P className="fs-2-33">
                  En ANCANA administraremos <b>nos encargamos de la gestión de tu casa</b> para que puedas concentrarte en disfrutar momentos con tu familia y amigos en tu refugio vacacional.
                </P>
              </Card>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            {/*
            <video
              src="https://d2zefcg3wq59mt.cloudfront.net/learn/ba%CC%81sicos_sobre_co-propiedad%20(720p).mp4#t=0.001"
              style={{ width: '100%' }}
              controls
              preload="metadata"
            />
            */}
            <video
              src={`https://d2zefcg3wq59mt.cloudfront.net/learn/what-is-co-ownership-es-720.mp4`}
              controls
              poster={`https://d2zefcg3wq59mt.cloudfront.net/learn/what-is-co-ownership-es-thumb.png`}
              preload="none"
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default FractionalSection;
